<template>
  <div class="card p-lg-3 p-2 pb-lg-5 pb-2 mb-1">
    <validation-observer ref="addClientForm">
      <b-row class="d-lg-flex justify-content-between">
        <b-col sm="12" md="6" class="">
          <div>
            <h6 class="mt-1">Client Assignments</h6>
            <validation-provider
              #default="{ errors }"
              name="Assignment"
              rules="required"
            >
              <v-select
                id="assignment_id"
                v-model="addForm.assignment_id"
                :options="assignmentOptions"
                :get-option-label="getSelectedAssignment"
                :get-option-value="getOptionValue"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
        </b-col>
        <b-col sm="12" md="6" class="">
          <div>
            <h6 class="mt-1">Client Users</h6>
            <validation-provider
              #default="{ errors }"
              name="User"
              rules="required"
            >
              <v-select
                id="user_id"
                v-model="addForm.user_id"
                :options="userOptions"
                :get-option-label="getSelected"
                :get-option-value="getOptionValue"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="mt-2"
            @click="handleSubmit()"
          >
            Assign
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import { togglePasswordVisibility } from "@core/mixins/ui/forms"
import { email, required } from "@validations"
import { BButton, BCol, BRow } from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import vSelect from "vue-select"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import axios from "@/libs/axios"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import errorResponse from "@/libs/axiosError"

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      email,
      countries: [],
      userOptions: [],
      assignmentOptions: [],
      addForm: {
        client_id: "",
        assignment_id: "",
        user_id: "",
      },
    }
  },
  mounted() {
    this.getClientUsers()
    this.getClientAssignments()
  },
  methods: {
    getSelectedAssignment(option) {
      return (option && option.title) || ""
    },
    getSelected(option) {
      return (option && option.email) || ""
    },
    getOptionValue(option) {
      return (option && option.name) || ""
    },

    async handleSubmit() {
      this.$refs.addClientForm.validate().then((success) => {
        if (success) {
          const formData = {
            client_id: this.$route.params.id,
            assignment_id: this.addForm.assignment_id.id,
            user_id: this.addForm.user_id.id,
          }
          axios
            .post("/client/add-assignment-user", formData)
            .then((res) => {
              if (res.data.error) {
                let errorText = ""
                let errorCount = 0
                // eslint-disable-next-line no-restricted-syntax, no-unused-vars
                for (const [key, value] of Object.entries(res.data.messages)) {
                  console.log(key, value)
                  if (errorCount === 0) {
                    errorText += value[0]
                  } else {
                    errorText += ` & ${value[0]} `
                  }
                  // eslint-disable-next-line no-plusplus
                  errorCount++
                }
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: "Something went Wrong!",
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                    text: errorText,
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Assignment Mapping`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `You have successfully Mapped Assignment!`,
                  },
                })
                this.$router.push({
                  path: "/clients-list",
                })
                this.modalShow = false
              }
            })
            .catch((error) => {
              console.log(error)
              errorResponse(error, this.$router)
              this.modalShow = false
            })
        }
      })
    },
    async getClientUsers() {
      axios
        .get(`/client/users/${this.$route.params.id}`)
        .then((res) => {
          this.userOptions = res.data
        })
        .catch((error) => {
          console.log(error)
          errorResponse(error, this.$router)
        })
    },
    async getClientAssignments() {
      axios
        .get(`/client/assignments/${this.$route.params.id}`)
        .then((res) => {
          this.assignmentOptions = res.data
        })
        .catch((error) => {
          console.log(error)
          errorResponse(error, this.$router)
        })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.button {
  background-color: rgb(3, 77, 133);
  color: #fff;
}

.demo-inline-spacing {
  margin-top: -15px;
}
#present_job {
  overflow: overlay;
}
.title {
  margin-left: 5px;
}
.img {
  margin-right: 10px;
  width: 35px;
  height: 35px;
}
.file_bg {
  background-color: #dcdcdc;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
